var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "visitor-scoring",
      class: { "visitor-scoring--activated": _vm.isVegaActivated },
    },
    [
      _vm.isVegaActivated &&
      _vm.isVegaWidgetEnabled &&
      !_vm.isVegaPaused &&
      !_vm.vegaWillExceedDailyLimit &&
      _vm.score !== null
        ? _c("div", { staticClass: "visitor-scoring--scale" }, [
            _c(
              "svg",
              {
                attrs: {
                  width: "200",
                  height: "10",
                  viewBox: "0 0 200 10",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("rect", {
                  attrs: {
                    x: "200",
                    y: "10",
                    width: "200",
                    height: "10",
                    rx: "5",
                    transform: "rotate(180 200 10)",
                    fill: "url(#paint0_linear_8779_948)",
                  },
                }),
                _c(
                  "defs",
                  [
                    _c(
                      "linearGradient",
                      {
                        attrs: {
                          id: "paint0_linear_8779_948",
                          x1: "400",
                          y1: "15",
                          x2: "200",
                          y2: "15.0001",
                          gradientUnits: "userSpaceOnUse",
                        },
                      },
                      [
                        _c("stop", { attrs: { "stop-color": "#029ef2" } }),
                        _c("stop", {
                          attrs: {
                            offset: "0.250000",
                            "stop-color": "#11f5b8",
                          },
                        }),
                        _c("stop", {
                          attrs: {
                            offset: "0.500000",
                            "stop-color": "#5bf20a",
                          },
                        }),
                        _c("stop", {
                          attrs: {
                            offset: "0.680000",
                            "stop-color": "#fcd303",
                          },
                        }),
                        _c("stop", {
                          attrs: { offset: "1", "stop-color": "#ff004e" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _c("div", { staticClass: "visitor-scoring--scale" }, [
            _c(
              "svg",
              {
                attrs: {
                  width: "200",
                  height: "10",
                  viewBox: "0 0 200 10",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("g", { staticStyle: { "mix-blend-mode": "multiply" } }, [
                  _c("rect", {
                    attrs: {
                      x: "200",
                      y: "10",
                      width: "200",
                      height: "10",
                      rx: "5",
                      transform: "rotate(180 200 10)",
                      fill: "url(#paint0_linear_8795_804)",
                    },
                  }),
                  _c("rect", {
                    attrs: {
                      x: "200",
                      y: "10",
                      width: "200",
                      height: "10",
                      rx: "5",
                      transform: "rotate(180 200 10)",
                      fill: "#C4C4C4",
                      "fill-opacity": "0.8",
                    },
                  }),
                ]),
                _c(
                  "defs",
                  [
                    _c(
                      "linearGradient",
                      {
                        attrs: {
                          id: "paint0_linear_8795_804",
                          x1: "400",
                          y1: "15",
                          x2: "200",
                          y2: "15.0001",
                          gradientUnits: "userSpaceOnUse",
                        },
                      },
                      [
                        _c("stop", { attrs: { "stop-color": "#16F7F7" } }),
                        _c("stop", {
                          attrs: {
                            offset: "0.211755",
                            "stop-color": "#87F788",
                          },
                        }),
                        _c("stop", {
                          attrs: {
                            offset: "0.435245",
                            "stop-color": "#EDF723",
                          },
                        }),
                        _c("stop", {
                          attrs: { offset: "0.59375", "stop-color": "#F0A941" },
                        }),
                        _c("stop", {
                          attrs: {
                            offset: "0.826165",
                            "stop-color": "#F76A48",
                          },
                        }),
                        _c("stop", {
                          attrs: { offset: "1", "stop-color": "#FF004E" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
      _vm.isVegaActivated &&
      _vm.isVegaWidgetEnabled &&
      !_vm.isVegaPaused &&
      !_vm.vegaWillExceedDailyLimit &&
      _vm.score !== null
        ? _c(
            "div",
            {
              staticClass: "visitor-scoring--pointer",
              style: _vm.pointerPosition,
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "8",
                    height: "16",
                    viewBox: "0 0 8 16",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M3.79961 5L0.508713 1.25L7.09051 1.25L3.79961 5Z",
                      fill: "#626262",
                    },
                  }),
                  _c("line", {
                    attrs: {
                      x1: "3.82019",
                      y1: "6",
                      x2: "3.82019",
                      y2: "16",
                      stroke: "#626262",
                      "stroke-width": "3",
                    },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.isVegaActivated &&
      _vm.isVegaWidgetEnabled &&
      !_vm.isVegaPaused &&
      !_vm.vegaWillExceedDailyLimit
        ? _c("div", { staticClass: "visitor-scoring--percent" }, [
            _vm._v("\n    " + _vm._s(_vm.scoringPercent) + "\n  "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }