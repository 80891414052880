<template>
  <div class="visitor-scoring" :class="{ 'visitor-scoring--activated': isVegaActivated }">
    <div v-if="isVegaActivated && isVegaWidgetEnabled && !isVegaPaused && !vegaWillExceedDailyLimit && score !== null" class="visitor-scoring--scale">
      <svg width="200" height="10" viewBox="0 0 200 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="200" y="10" width="200" height="10" rx="5" transform="rotate(180 200 10)" fill="url(#paint0_linear_8779_948)" />
        <defs>
          <linearGradient id="paint0_linear_8779_948" x1="400" y1="15" x2="200" y2="15.0001" gradientUnits="userSpaceOnUse">
            <stop stop-color="#029ef2"></stop>
            <stop offset="0.250000" stop-color="#11f5b8"></stop>
            <stop offset="0.500000" stop-color="#5bf20a"></stop>
            <stop offset="0.680000" stop-color="#fcd303"></stop>
            <stop offset="1" stop-color="#ff004e"></stop>
          </linearGradient>
        </defs>
      </svg>
    </div>

    <div v-else class="visitor-scoring--scale">
      <svg width="200" height="10" viewBox="0 0 200 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g style="mix-blend-mode: multiply">
          <rect x="200" y="10" width="200" height="10" rx="5" transform="rotate(180 200 10)" fill="url(#paint0_linear_8795_804)" />
          <rect x="200" y="10" width="200" height="10" rx="5" transform="rotate(180 200 10)" fill="#C4C4C4" fill-opacity="0.8" />
        </g>
        <defs>
          <linearGradient id="paint0_linear_8795_804" x1="400" y1="15" x2="200" y2="15.0001" gradientUnits="userSpaceOnUse">
            <stop stop-color="#16F7F7" />
            <stop offset="0.211755" stop-color="#87F788" />
            <stop offset="0.435245" stop-color="#EDF723" />
            <stop offset="0.59375" stop-color="#F0A941" />
            <stop offset="0.826165" stop-color="#F76A48" />
            <stop offset="1" stop-color="#FF004E" />
          </linearGradient>
        </defs>
      </svg>
    </div>

    <div v-if="isVegaActivated && isVegaWidgetEnabled && !isVegaPaused && !vegaWillExceedDailyLimit && score !== null" class="visitor-scoring--pointer" :style="pointerPosition">
      <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.79961 5L0.508713 1.25L7.09051 1.25L3.79961 5Z" fill="#626262" />
        <line x1="3.82019" y1="6" x2="3.82019" y2="16" stroke="#626262" stroke-width="3" />
      </svg>
    </div>

    <div v-if="isVegaActivated && isVegaWidgetEnabled && !isVegaPaused && !vegaWillExceedDailyLimit" class="visitor-scoring--percent">
      {{ scoringPercent }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'VisitorScoring',
  props: {
    score: {
      type: Number,
      default: 0
    },
    online: {
      type: Boolean,
      default: false
    },
    isVegaWidgetEnabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    pointerPosition() {
      return `left: ${this.scoring * 2}px`
    },
    scoring() {
      const score = this.score > 1 ? this.score / 10 : this.score
      return score ? score * 100 : null
    },
    scoringPercent() {
      if (this.scoring) {
        return `${Math.floor(this.scoring)} %`
      }
      return null
    }
  }
}
</script>
<style lang="scss" scoped>
.visitor-scoring {
  width: 200px;
  height: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  &--pointer {
    position: absolute;
    z-index: 1;
    top: 0px;
    transition: all 0.6s linear;
  }

  &--activated {
    width: 260px !important;
  }

  &--percent {
    margin-left: 10px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 17.5px;
    line-height: 21px;
    text-align: center;
    color: #726f7b;
  }
}
</style>
